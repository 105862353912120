@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden; /* Prevent scrolling on the entire page */
}

.app {
  display: flex;
  position: relative;
}

.content {
  flex: 1; /* Take the remaining space beside the sidebar */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; /* Prevent scrolling on the parent */
}

::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */ 
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}